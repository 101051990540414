import React from "react"
import { AppProvider, Page } from "@shopify/polaris"

const ErrorPage = () => {
  return (
    <AppProvider>
      <Page title={`Page Not Found`} />
    </AppProvider>
  )
}

export default ErrorPage
